import {
    Card,
    Box,
    Typography,
    TextField,
    Stack,
    Button,
    Grid,
    CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMessage } from './../layouts/Header';
import Error from '../components/Error';
import Image from '../components/Image';
import useNavigateWithParams from '../hooks/useNavigateWithParams';
import axios from 'axios';
import { validationErrorHandler } from '../utils/functions';

function CreatePassword() {
    const state = useState(useLocation().state || { otp: '', isVerified: false })[0];
    const navigate = useNavigateWithParams();
    const { showErrors, showSuccess, showError } = useMessage();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [passError, setPassError] = useState('');

    const onSubmit = async e => {
        e.preventDefault();
        if (password.length < 8)
            return setPassError('Password must contains atleast 8 characters.');
        if (password !== confirmPass) return setPassError("Password didn't match.");

        setLoading(true);
        try {
            const response = await axios.patch(`/create-password`, {
                otp: state?.otp,
                password,
            });
            const { success, message, errors } = response.data;

            if (!success) {
                return showErrors(errors);
            }

            showSuccess(message);
            navigate('/login'); // implement to stop navigating back after hitting this route
            //  bugFix
        } catch (err) {
            validationErrorHandler(err, showError);
        } finally {
            setLoading(true);
        }
    };

    return state.isVerified ? (
        <Box
            maxWidth='900px'
            mx='auto'
            display='flex'
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            justifyContent='center'
            minHeight='100vh'>
            <Card
                sx={{
                    borderRadius: '8px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: { xs: 'transparent', sm: 'divider' },
                    width: { xs: '100%', sm: '512px' },
                    pt: 6,
                    px: { xs: 3, sm: 5 },
                    pb: 0,
                    transition: '.2s',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                elevation={0}>
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    spacing={1}
                    mb={5.5}>
                    <Image
                        sx={{
                            height: 70,
                        }}
                        name='logo.jpg'
                    />

                    <Typography pt={2} variant='subtitle1' color='white'>
                        Reset password
                    </Typography>
                    <Typography variant='body2' align='center' color='text.secondary'>
                        Guard your digital gate with a strong password: <br />a mix of characters,
                        length, and uniqueness.
                    </Typography>
                </Stack>

                <>
                    {passError && (
                        <Typography
                            variant='caption'
                            color='text.secondary'
                            component='div'
                            gutterBottom
                            sx={{ color: '#d32f2f' }}>
                            {passError}
                        </Typography>
                    )}
                    <Box mt={4}>
                        <form onSubmit={onSubmit}>
                            <Box>
                                <TextField
                                    variant='outlined'
                                    placeholder='New password'
                                    name='password'
                                    kind='create-account'
                                    onChange={e => {
                                        setPassError('');
                                        setPassword(e.target.value);
                                    }}
                                    fullWidth
                                    sx={{ mb: 1.5 }}
                                />
                                <TextField
                                    variant='outlined'
                                    kind='create-account'
                                    placeholder='Repeat new password'
                                    name='confirmPass'
                                    onChange={e => {
                                        setPassError('');
                                        setConfirmPass(e.target.value);
                                    }}
                                    fullWidth
                                />
                            </Box>
                            <Grid container>
                                <Grid item xs></Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        sx={{ my: 3, py: 1.1 }}
                                        disabled={loading || !(password && confirmPass)}
                                        endIcon={
                                            loading && (
                                                <CircularProgress
                                                    sx={{ color: 'text.primary', fontSize: '16px' }}
                                                />
                                            )
                                        }>
                                        Reset password
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    <Box p={3}>
                        <Typography textAlign='center' color='text.secondary'>
                            Nevermind
                            <Button component={Link} to='/login' sx={{ px: 1, color: '#7F8185' }}>
                                Sign in
                            </Button>
                        </Typography>
                    </Box>
                </>
            </Card>
        </Box>
    ) : (
        <Error errorCode='400' error='Your client has issued a malformed or illegal request.' />
    );
}

export default CreatePassword;
