import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

const Loading = ({ message }) => {
    console.log(message);
    return (
        <Grid
            container
            spacing={0}
            justifyContent='center'
            alignItems='center'
            flexWrap='nowrap'
            style={{ minHeight: '100vh', color: 'white' }}>
            <Grid item align='center'>
                <CircularProgress sx={{ mx: 3 }} />
            </Grid>
            <Grid item>{message}</Grid>
        </Grid>
    );
};

export default Loading;
