import {
    Card,
    Box,
    Typography,
    TextField,
    Stack,
    Button,
    Chip,
    FormControlLabel,
    Checkbox,
    Backdrop,
} from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Image from '../components/Image';
import { Link as routerLink, useLocation } from 'react-router-dom';
import { useForm, Form, Submit } from '../hooks/useForm';
import { Input } from '../hooks/useForm/inputs';
import { useMessage } from './../layouts/Header';
import {
    env,
    getQueryParams,
    not,
    getDomain,
    addMetaDescription,
    isDefined,
} from '../utils/functions';
import Loading from '../components/Loading';
import useErrorHandler from '../hooks/useErrorHandler';
import { setCookie } from '../utils/cookies';
import useNavigateWithParams from '../hooks/useNavigateWithParams';
import { emailValidator } from '../utils/validators';

const functions = createContext();

const Login = () => {
    const [step, setStep] = useState(<Step1 />);
    const location = useLocation();
    const navigate = useNavigateWithParams();

    useEffect(() => {
        if (not(getQueryParams(location, 'redirectto')))
            navigate(`?redirectto=${encodeURIComponent(process.env.REACT_APP_HR)}`);

        addMetaDescription('Enter the AI realm. Join us with your login!');
    }, [location, navigate]);

    return (
        <functions.Provider value={{ setStep }}>
            <Box
                maxWidth='900px'
                mx='auto'
                display='flex'
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent='center'
                minHeight='100vh'>
                <Card
                    sx={{
                        borderRadius: '8px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: { xs: 'transparent', sm: 'divider' },
                        width: { xs: '100%', sm: '448px', xl: '512px' },
                        pt: 6,
                        px: { xs: 3, sm: 5 },
                        pb: 0,
                        transition: '.2s',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    elevation={0}>
                    {step}
                </Card>
            </Box>
        </functions.Provider>
    );
};

export default Login;

function Step1() {
    const { setStep } = useContext(functions);
    const navigate = useNavigateWithParams();
    const errorHandler = useErrorHandler();

    const handlers = useForm(
        {
            email: {
                required: true,
                validator: emailValidator,
            },
        },
        { Input: TextField }
    );

    const onSubmit = function (res) {
        const { success, backupEmail } = res.data;
        if (success) {
            setStep(<Step2 email={handlers.values.email} backupEmail={backupEmail} />);
            return;
        }
    };

    return (
        <>
            <Stack
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={1}
                mb={4}>
                <Image
                    sx={{
                        height: 70,
                    }}
                    name='logo.jpg'
                />
                <Typography mt={2} variant='h5' gutterBottom>
                    Sign in
                </Typography>
                <Typography color='text.secondary' lineHeight='28px'>
                    Enter your credentials below
                </Typography>
            </Stack>
            <Box mb={2}>
                <Form
                    handlers={handlers}
                    action='/valid-email'
                    method='POST'
                    onError={errorHandler}
                    onSubmit={onSubmit}>
                    <Box>
                        <Input
                            variant='outlined'
                            placeholder='Email Address'
                            name='email'
                            fullWidth
                        />
                    </Box>

                    <Stack
                        direction='row'
                        spacing={2}
                        justifyContent='space-between'
                        alignItems='center'
                        width='100%'
                        mt={5}>
                        <Button
                            variant='text'
                            sx={{ px: 0.7 }}
                            onClick={() => navigate('/sign-up')}>
                            Create account
                        </Button>

                        <Submit loaderProps={{ color: 'primary', size: 20 }}>
                            {loader => (
                                <Button
                                    type='submit'
                                    variant='contained'
                                    disabled={loader}
                                    endIcon={loader}>
                                    Next
                                </Button>
                            )}
                        </Submit>
                    </Stack>
                </Form>
            </Box>
            <Box py={3}>
                <Typography textAlign='center' color='text.secondary'>
                    Enter the AI realm. Join us with login!
                </Typography>
            </Box>
        </>
    );
}

function Step2(props) {
    const { setStep } = useContext(functions);
    const [email] = useState(props.email);
    const [backupEmail] = useState(props.backupEmail);
    const { showError } = useMessage();
    const location = useLocation();
    const [message, setMessage] = useState();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const redirectTo = getQueryParams(location, 'redirectto');
    const [showPassword, setShowPassword] = useState(false);
    const errorHandler = useErrorHandler();

    const handlers = useForm(
        {
            email: {
                required: true,
                value: email,
            },
            password: {
                required: true,
            },
        },
        {
            Input: TextField,
        }
    );

    const errors = handlers.errors;

    function onSubmit(res) {
        const { success, errors, token } = res.data;

        if (not(success)) {
            handleClose();

            if (errors) {
                return showError(errors);
            }

            return showError(
                'Something went wrong, Please contact the administrator if the issue persists'
            );
        }

        setCookie('accessToken', token, {
            domain: getDomain(env('COOKIE_DOMAIN')),
        });

        window.location.replace(redirectTo);
        setMessage(`Login Successful, redirecting you to ${redirectTo}`);
    }

    return (
        <>
            {' '}
            <Stack
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={1}
                mb={4}>
                <Image
                    sx={{
                        height: 70,
                    }}
                    name='logo.jpg'
                />
                <Typography mt={2} variant='h5' color='white' gutterBottom>
                    Sign in
                </Typography>
                <Chip label={email} variant='outlined' onClick={() => setStep(<Step1 />)} />
            </Stack>
            <Box mb={2}>
                <Form
                    handlers={handlers}
                    action='/login'
                    method='post'
                    retainOnSubmit
                    onSubmit={onSubmit}
                    onError={e => {
                        errorHandler(e);
                        handleClose();
                    }}>
                    <Input
                        variant='outlined'
                        type={showPassword ? 'text' : 'password'}
                        kind='create-account'
                        placeholder='password'
                        name='password'
                        fullWidth
                    />

                    <Stack
                        direction='row'
                        spacing={2}
                        justifyContent='space-between'
                        alignItems='center'
                        width='100%'
                        mt={5}>
                        <FormControlLabel
                            label='Show password'
                            componentsProps={{
                                typography: { variant: 'caption' },
                            }}
                            sx={{
                                flexGrow: 1,
                                '& .MuiButtonBase-root.MuiCheckbox-root': {
                                    p: 0,
                                    px: 1,
                                },
                            }}
                            control={
                                <Checkbox onChange={(e, checked) => setShowPassword(checked)} />
                            }
                        />
                        <Submit loaderProps={{ color: 'primary', size: 20 }}>
                            {loading => (
                                <Button
                                    type='submit'
                                    variant='contained'
                                    onClick={() => {
                                        if (
                                            isDefined(errors.password) &&
                                            errors.password === '' &&
                                            Boolean(handlers.values.password.trim())
                                        ) {
                                            setMessage('Please wait, loggin you in...');
                                            handleOpen();
                                        }
                                    }}
                                    disabled={loading}
                                    endIcon={loading}>
                                    Next
                                </Button>
                            )}
                        </Submit>
                    </Stack>
                </Form>
            </Box>
            <Box py={3}>
                <Typography textAlign='center' color='text.secondary'>
                    Forget the magic word?
                    <Button
                        component={routerLink}
                        to='/login/forgot-password'
                        state={{ email, backupEmail }}
                        sx={{
                            px: 1,
                            py: 0,
                            m: 0,
                            color: '#7F8185',
                            lineHeight: 1.2,
                        }}>
                        Reset Password
                    </Button>
                </Typography>
            </Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                open={open}>
                <Loading message={message} />
            </Backdrop>
        </>
    );
}
