import { createTheme } from '@mui/material';
import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

const ThemeContextProvider = props => {
    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(26,115,232)',
            },
            background: {
                default: '#f0f4f9',
                // paper: '#0A0A0A',
            },
            text: {
                secondary: '#7F8185',
                disabled: '#7F8185',
            },
            action: {
                disabledBackground: '#57595D',
                disabled: '#D7D6DA',
            },

            divider: '#57595d45',
        },
        components: {
            MuiCard: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            boxShadow: 'rgba(255, 255, 255, .2) 0px 2px 8px 0px',
                        },
                    }),
                },
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'contained' },
                        style: ({ theme }) => ({
                            padding: '8px 16px',
                            [theme.breakpoints.up('xl')]: {
                                padding: '10px 24px',
                            },
                            border: 'none',
                        }),
                    },
                ],
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        fontWeight: 500,
                        padding: '5px 24px',
                    },
                },
            },
            MuiTextField: {
                variants: [
                    {
                        props: { variant: 'outlined', size: 'x-small' },
                        style: {
                            '& input.MuiInputBase-input.MuiOutlinedInput-input': {
                                padding: '10px 12px ',
                            },
                            '& .MuiFormLabel-root.MuiInputLabel-root': {
                                top: '-9px',
                                '&.Mui-focused': {
                                    top: 0,
                                },
                            },
                        },
                    },
                ],
                styleOverrides: {
                    root: {
                        'input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                            margin: '0',
                        },

                        input: {
                            '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                // color: '#7F8185',
                            },
                        },
                        'input:-webkit-autofill': {
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#7F8185',
                            transition: 'background-color 5000s ease-in-out 0s',
                            boxShadow: 'inset 0 0 20px 20px #23232329',
                        },

                        // '.MuiInputBase-root.MuiOutlinedInput-root': {
                        //     '&:focus-visible': {
                        //         outline: 'none',
                        //     },

                        //     '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        //         border: 'none',
                        //     },

                        //     '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
                        //         borderColor: 'rgb(0 0 0 / 23%)',
                        //     },
                        //     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        //         borderColor: 'rgb(0 0 0 / 23%)',
                        //         borderWidth: '1px',
                        //     },
                        // },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        '&:hover  fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgb(0 0 0 / 23%)',
                        },
                        // '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                        //     border: 'none',
                        // },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        height: '28px',
                    },
                },
            },
            // MuiInputLabel: {
            //     styleOverrides: {
            //         root: {
            //             top: '-9px',
            //             '&.Mui-focused': {
            //                 top: 0,
            //             },
            //         },
            //     },
            // },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    );
};

export default ThemeContextProvider;
