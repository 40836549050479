import { Box, Container, Grid, Typography, Stack } from '@mui/material';
import React from 'react';
import Image from './Image';

const Error = props => {
    const { error, errorCode } = props;
    return (
        <Container maxWidth='lg'>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='calc(100vh - 80px)'
                my={5}>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Stack direction='horizontal' mb={2}>
                            <Image name='logo.jpg' sx={{ height: '50px' }} />{' '}
                            {/* <Typography
                                variant='h3'
                                fontWeight={500}
                                mx={1}
                                letterSpacing={'1.8px'}>
                                Catch
                            </Typography> */}
                        </Stack>
                        <Box ml={2}>
                            <Typography variant='h6' color='text.secondary' fontWeight={400} mb={3}>
                                <Typography
                                    variant='h5'
                                    fontWeight={500}
                                    color='text.primary'
                                    component='span'>
                                    {errorCode}. {'   '}
                                </Typography>
                                That's an error.
                            </Typography>
                            <Typography variant='h6' mb={1}>
                                {error}
                            </Typography>
                            <Typography variant='subtitle1' color='text.secondary'>
                                That's all we know
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} align='center'>
                        <Image
                            name='robot.png'
                            sx={{ maxWidth: '384px', width: '100%', mt: { xs: 4, md: 0 } }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Error;
