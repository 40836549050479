import { Box } from '@mui/system';
import React from 'react';

const Image = props => {
    const { name, sx } = props;
    return (
        <Box
            component='img'
            sx={{ maxWidth: '100%', ...sx }}
            src={`${process.env.PUBLIC_URL}/images/${name}`}
        />
    );
};

export default Image;
