function getQueryParams(location, nameToFind) {
    const queryString = location.search.slice(1);
    const queries = queryString.split('&');
    for (const query of queries) {
        const [name, value] = query.split('=');
        if (name === nameToFind) {
            return decodeURIComponent(value);
        }
    }
    return undefined;
}

const not = k => !k;

const env = name => {
    const nodeENV = process.env.NODE_ENV.toUpperCase();

    return process.env[`REACT_APP_${nodeENV}_${name}`] || process.env[`REACT_APP_${name}`];
};

const getDomain = url => url.replace(/(http:\/\/|https:\/\/)/, '');

const hideEmail = email => email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');

const validationErrorHandler = (error, showError) => {
    const { status, data } = error.response;
    if (status === 400 && data.errors) {
        const validationErrors = data.errors.map(error => error.message);
        showError(validationErrors.toString());
    } else {
        console.log(error);
    }
};

const addMetaDescription = metaDescription => {
    const metaTag = document.querySelector('meta[name="description"]');
    metaTag.setAttribute('content', metaDescription);

    document.head.appendChild(metaTag);
};

const isEmpty = obj => Object.keys(obj).length === 0;
const isObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
const isDefined = v => typeof v !== 'undefined';

export {
    getQueryParams,
    not,
    env,
    getDomain,
    hideEmail,
    validationErrorHandler,
    addMetaDescription,
    isObject,
    isDefined,
    isEmpty,
};
