import './utils/axios';
import { Routes, Route, Navigate } from 'react-router-dom';
import CreateAccount from './pages/CreateAccount';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import CreatePassword from './pages/CreatePassword';
import Error from './components/Error';
import Header from './layouts/Header';

function App() {
    return (
        <Header>
            <Routes>
                <Route path='/' element={<Navigate to='/login' />} />
                <Route path='/sign-up' element={<CreateAccount />} />

                <Route path='/login' element={<Login />} />
                <Route path='/login/forgot-password' element={<ForgotPassword />} />
                <Route path='/login/create-password' element={<CreatePassword />} />
                <Route
                    path='*'
                    element={
                        <Error
                            error='The requested URL was not found on this server.'
                            errorCode='404'
                        />
                    }
                />
            </Routes>
        </Header>
    );
}

export default App;
