import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateWithParams = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (path, options) => {
        const paramsIndex = path.indexOf('?') + 1;
        const query = paramsIndex ? path.slice(paramsIndex) : '';
        const prevQuery = location.search.slice(1);
        const pathWithoutQuery = path.substring(
            0,
            paramsIndex ? paramsIndex - 1 : +Infinity
        );
        const newPath =
            pathWithoutQuery + '?' + prevQuery + (query && `&${query}`);

        navigate(newPath, options);
    };
};

export default useNavigateWithParams;
