import {
    Card,
    Box,
    Typography,
    Grid,
    Stack,
    Button,
    TextField,
    Select,
    MenuItem,
    Backdrop,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Image from '../components/Image';
import { Link, useLocation } from 'react-router-dom';
import { Input } from '../hooks/useForm/inputs/index';
import { useForm, Form, Submit } from '../hooks/useForm';
import { useMessage } from './../layouts/Header';
import { setCookie } from '../utils/cookies';
import { emailValidator, phoneValidator } from '../utils/validators';
import { addMetaDescription, env, getDomain, getQueryParams } from '../utils/functions';
import useNavigateWithParams from './../hooks/useNavigateWithParams';
import Loading from '../components/Loading';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useErrorHandler from '../hooks/useErrorHandler';

const CreateAccount = () => {
    const location = useLocation();
    const navigate = useNavigateWithParams();
    const [showPassword, setShowPassword] = useState(false);
    const errorHandler = useErrorHandler();
    const [message, setMessage] = useState('');
    const { showError, showSuccess } = useMessage();
    const [open, setOpen] = useState(false);

    const backdropClose = () => setOpen(false);

    const backdropOpen = () => setOpen(true);

    const handlers = useForm(
        useMemo(
            () => ({
                firstName: { required: true, minLength: 3 },
                lastName: '',
                dob: { required: true },
                gender: { required: true, value: '' },
                email: { required: true, validator: emailValidator },
                phone: { required: true, validator: phoneValidator },
                password: {
                    required: true,
                    validator: v =>
                        v.length >= 8 ? '' : 'Passowrd must contains at least 8 characters',
                },
            }),
            []
        ),
        { Input: TextField }
    );

    const errors = handlers.errors;
    const setValues = handlers.setValues;
    const values = handlers.values;

    console.log(errors);

    const customOnchangeHandler = e => {
        const { name, value } = e.target;
        setValues({ [name]: value });
    };

    const onSubmit = response => {
        const { success, errors, token } = response.data;
        if (success) {
            showSuccess('You have successfully created your account!');

            setCookie('accessToken', token, {
                domain: getDomain(env('COOKIE_DOMAIN')),
            });

            const redirectto = getQueryParams(location, 'redirectto');
            setMessage(`Login Successful, redirecting you to ${redirectto}`);
            window.location.replace(redirectto);
            return backdropOpen();
        }
        backdropClose();
        showError(errors);
    };

    useEffect(() => {
        if (!getQueryParams(location, 'redirectto'))
            navigate(`?redirectto=${encodeURIComponent(process.env.REACT_APP_HR)}`);

        addMetaDescription('Enter the AI realm. Join us with your Registeration!');
    }, [location, navigate]);

    return (
        <Box
            mx='auto'
            display='flex'
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            justifyContent='center'
            minHeight='100vh'>
            <Card
                sx={{
                    borderRadius: '8px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: { xs: 'transparent', sm: 'divider' },
                    width: { xs: '100%', sm: '618px', md: '900px', xl: '1200px' },
                    p: { xs: 3, sm: 5 },
                    transition: '.2s',
                }}
                elevation={0}>
                <Image name='logo.jpg' sx={{ height: '50px' }} />{' '}
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item xs={12} md={7}>
                        <Typography variant='h5' mb={5}>
                            Create your {process.env.REACT_APP_COMPANY} Account
                        </Typography>
                        <Form
                            handlers={handlers}
                            action='/create'
                            method='post'
                            onSubmit={onSubmit}
                            onError={errorHandler}>
                            <Grid container columnSpacing={1} rowSpacing={3.5}>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        variant='outlined'
                                        size='small'
                                        label='First Name'
                                        name='firstName'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        variant='outlined'
                                        size='small'
                                        label='Last Name'
                                        name='lastName'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={null}
                                            onChange={newValue => {
                                                setValues({
                                                    dob: new Date(newValue.$d),
                                                });
                                            }}
                                            disableFuture
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    size: 'small',
                                                    fullWidth: true,
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {errors.dob ? (
                                        <Typography
                                            variant='cation'
                                            pl={3}
                                            mt={0.5}
                                            color='warning.dark'
                                            fontSize={12}>
                                            {errors.dob}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant='caption'
                                            pl={3}
                                            mt={0.5}
                                            color='text.secondary'>
                                            Your date of birth
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Select
                                        name='gender'
                                        displayEmpty
                                        fullWidth
                                        value={values.gender}
                                        onChange={customOnchangeHandler}
                                        sx={{
                                            '.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':
                                                {
                                                    padding: '8.5px 14px',
                                                },
                                        }}>
                                        <MenuItem
                                            disabled
                                            value=''
                                            sx={{ color: 'text.secondary' }}>
                                            Gender
                                        </MenuItem>
                                        <MenuItem value={'male'}>Male</MenuItem>
                                        <MenuItem value={'female'}>Female</MenuItem>
                                    </Select>
                                    {errors.gender ? (
                                        <Typography
                                            variant='cation'
                                            pl={3}
                                            mt={0.5}
                                            color='warning.dark'
                                            fontSize={12}>
                                            {errors.dob}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant='cation'
                                            color='warning.dark'
                                            fontSize={12}
                                            pl={3}
                                            mt={0.5}>
                                            {errors.gender}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <Input
                                        variant='outlined'
                                        size='small'
                                        label='Email Address'
                                        name='email'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        variant='outlined'
                                        size='small'
                                        label='Phone Number'
                                        name='phone'
                                        type='number'
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Input
                                        variant='outlined'
                                        size='small'
                                        label='Password'
                                        name='password'
                                        type={showPassword ? 'text' : 'password'}
                                        s
                                        fullWidth
                                    />
                                </Grid>

                                <Stack
                                    direction={{ xs: 'column', md: 'row' }}
                                    justifyContent='space-between'
                                    alignItems={{ xs: 'flex-start', md: 'center' }}
                                    width={'100%'}>
                                    <FormControlLabel
                                        label='Show password'
                                        componentsProps={{
                                            typography: { variant: 'caption' },
                                        }}
                                        sx={{ ml: 0 }}
                                        control={
                                            <Checkbox
                                                onChange={(e, checked) => setShowPassword(checked)}
                                            />
                                        }
                                    />
                                    <Typography
                                        variant='caption'
                                        pl={1.5}
                                        lineHeight={1.5}
                                        color='text.secondary'>
                                        Use 8 or more characters with a mix of letters, numbers &
                                        symbols
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction='row'
                                    spacing={2}
                                    justifyContent='space-between'
                                    width='100%'
                                    my={4}>
                                    <Button variant='text' LinkComponent={Link} to='/login'>
                                        Sign in instead
                                    </Button>
                                    <Submit>
                                        {loader => (
                                            <Button
                                                variant='contained'
                                                type='submit'
                                                endIcon={loader}
                                                disabled={Boolean(loader)}>
                                                Create
                                            </Button>
                                        )}
                                    </Submit>
                                </Stack>
                            </Grid>
                        </Form>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{ display: { xs: 'none', md: 'block' }, transition: '.2s' }}
                        align='center'
                        alignSelf='center'>
                        <Image name='account.png' />
                        <Typography variant='body1' fontWeight={300} color='text.secondary'>
                            One account. <br /> All of HR system working for you.
                        </Typography>
                    </Grid>
                </Grid>
            </Card>{' '}
            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={open}>
                <Loading message={message} />
            </Backdrop>
        </Box>
    );
};

export default CreateAccount;
