import React from "react";
import "./style/default-input.css";

const DefaultInput = props => {
    const { type, value, name, changeHandler, placeholder, disabled, error, helperText, ...rest } =
        props;
    return (
        <>
            <div className='default-input-outline'>
                <input
                    className='default-input'
                    type={type}
                    value={value}
                    name={name}
                    onChange={changeHandler}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...rest}
                />
            </div>
            {error && <span className='default-error'>{helperText}</span>}
        </>
    );
};

export default DefaultInput;
