import { Card, Box, Typography, TextField, Stack, Button, Chip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Image from '../components/Image';
import { Link, useLocation } from 'react-router-dom';
import { useForm, Form, Submit } from '../hooks/useForm';
import { Input } from '../hooks/useForm/inputs';
import axios from 'axios';
import { useMessage } from './../layouts/Header';
import { hideEmail } from '../utils/functions';
import useNavigateWithParams from '../hooks/useNavigateWithParams';

function ForgotPassword() {
    const [timeLeft, setTimeLeft] = useState(60);
    const email = useState(useLocation().state)[0];
    const { showErrors, showSuccess } = useMessage();
    const navigate = useNavigateWithParams();

    const handlers = useForm(
        {
            email: {
                required: true,
                value: email.email,
            },
            otp: {
                required: true,
            },
        },
        {
            Input: TextField,
        }
    );

    function onSubmit(res) {
        const { success, message, errors } = res.data;

        if (!success) {
            return showErrors(errors);
        }

        showSuccess(message);
        navigate('/login/create-password', {
            state: { otp: handlers.values.otp, isVerified: true },
        });
    }

    const tickTime = useCallback(() => {
        setTimeLeft(prevTime => {
            if (prevTime > 1) {
                setTimeout(tickTime, 1000);
                return --prevTime;
            }
        });
    }, [setTimeLeft]);

    const requestOTP = useCallback(async () => {
        setTimeLeft(60);
        try {
            const response = await axios.get(`/reset-code/${email.email}`);
            console.log(response.data);
            tickTime();
        } catch (e) {
            console.error(e);
        }
    }, [email, tickTime]);

    useEffect(() => {
        requestOTP();
    }, [requestOTP]);

    return (
        <Box
            maxWidth='900px'
            mx='auto'
            display='flex'
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            justifyContent='center'
            minHeight='100vh'>
            <Card
                sx={{
                    borderRadius: '8px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: { xs: 'transparent', sm: 'divider' },
                    width: { xs: '100%', sm: '512px' },
                    pt: 6,
                    px: { xs: 3, sm: 5 },
                    pb: 0,
                    transition: '.2s',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                elevation={0}>
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    spacing={1}
                    mb={1.5}>
                    <Image
                        sx={{
                            height: 70,
                        }}
                        name='logo.jpg'
                    />

                    <div style={{ textAlign: 'center', marginBottom: 16 }}>
                        <Typography variant='h5' gutterBottom color='text.primary'>
                            Account recovery
                        </Typography>
                        <Typography variant='subtitle1' lineHeight='24px' align='center' mb={2}>
                            {process.env.REACT_APP_COMPANY} wants to make sure it’s really you
                            trying to sign in
                        </Typography>
                        <Chip label={email.email} variant='outlined' component={Link} to='/login' />
                    </div>
                </Stack>
                <Box mt={4}>
                    <Form
                        onSubmit={onSubmit}
                        handlers={handlers}
                        onError={console.log}
                        action='/verify/reset-code'
                        method='post'>
                        <Typography variant='body2' mb={2} color='text.secondary'>
                            An email with a verification code was just sent to{' '}
                            {hideEmail(email.email)}
                        </Typography>
                        <Input
                            variant='outlined'
                            placeholder='Enter code'
                            name='otp'
                            kind='create-account'
                            fullWidth
                            sx={{ marginBottom: '4px' }}
                        />

                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            width='100%'
                            mt={4.5}>
                            <Typography>
                                <Button
                                    variant='text'
                                    color='primary'
                                    sx={{ px: '3px', fontWeight: 500 }}
                                    disabled={timeLeft > 0}
                                    onClick={requestOTP}>
                                    resend code
                                </Button>{' '}
                                {timeLeft ? `in ${timeLeft} seconds` : null}
                            </Typography>
                            <Submit loaderProps={{ color: 'primary', size: 20 }}>
                                {loader => (
                                    <Button variant='contained' type='submit' disabled={loader}>
                                        Next {loader}
                                    </Button>
                                )}
                            </Submit>
                        </Stack>
                    </Form>
                </Box>
                <Box p={3}>
                    <Typography textAlign='center' color='text.secondary'>
                        Nevermind
                        <Button component={Link} to='/login' sx={{ px: 1, color: '#7F8185' }}>
                            Sign in
                        </Button>
                    </Typography>
                </Box>
            </Card>
        </Box>
    );
}

export default ForgotPassword;
